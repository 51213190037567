<template>
	<div>
		<ul class="list-group my-1">
			<li class="list-group-item">
				<div class="form-check">
					<input class="form-check-input" type="radio" id="payByZelle" v-model="paymentMethod" value="zelle"
						@change="selectedPaymentMethodChange" />
					<label class="form-check-label" for="payByZelle">
						Pay by cash using Zelle Quickpay
					</label>
				</div>
			</li>
			<li class="list-group-item" v-if="paymentMethod == 'zelle'">
				<div class="col-12">
					<p class="text-muted">
						Please submit your payment via Zelle quickpay to explore@socaislands.com. New
						reservations will be held for two hours. If payment is not received within
						that time, the reservation will be cancelled. If you are submitting an
						additional payment to an existing reservation, then be sure to include your
						reservation ID in the description and we will update your account within two
						hours of receiving the payment.
					</p>
				</div>
			</li>
		</ul>
		<ul class="list-group my-1">
			<li class="list-group-item">
				<div class="form-check">
					<input class="form-check-input" type="radio" id="payByCard" v-model="paymentMethod" value="credit_card"
						@change="selectedPaymentMethodChange" />
					<label class="form-check-label" for="payByCard">
						Pay by card
						<span class="cc-logos">
							<img src="../../assets/img/Visa.png" alt="Visa" />
							<img src="../../assets/img/MasterCard.png" alt="MasterCard" />
							<img src="../../assets/img/Maestro.png" alt="Maestro" />
							<img src="../../assets/img/American-Express.png" alt="American Express" />
						</span>
					</label>
				</div>
			</li>
			<li class="list-group-item" v-show="paymentMethod == 'credit_card'">
				<div class="alert alert-success my-3 small">
					<i class="fas fa-lock"></i> This is a secure and SSL encrypted payment.
				</div>
				<div id="payment-element">
					<div class="d-flex justify-content-center">
						<div class="spinner-border" style="width: 5rem; height: 5rem" role="status">
							<span class="visually-hidden">Loading...</span>
						</div>
					</div>
					<!-- Mount the Payment Element here -->
				</div>
				<div class="alert alert-danger alert-dismissible my-3 small" id="payment-error-alert"
					v-if="showPaymentError">
					<i class="fas fa-bell"></i> There was a problem while charging your credit card.
					Please review your credit card details and/or contact your financial institution
					before trying again.
					<p>
						<strong>Response - {{ paymentErrorMessage }}</strong>
					</p>
					<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
import { writeBatch, doc, increment } from "firebase/firestore";
import { db, emailTemplates, collections, subCollections } from "../../firebaseConfig";
import { useToast } from "vue-toastification";
import { formatDate, formatCurrency } from "../../utils";

const toast = useToast();

export default {
	name: "payment-method",
	props: {
		initPaymentMethod: String,
		hideMethod: String,
		activeOrder: {
			type: Object,
			default: null,
		},
		receiptEmail: {
			type: String,
			default: null,
		},
		adHocPayment: {
			type: Boolean,
			default: false,
		},
		paymentDescriptor: {
			type: String,
			default: "Payment to Soca Islands",
		},
		captureMethod: {
			type: String,
			default: "automatic",
		},
	},
	components: {},
	computed: {
		totalTravellers() {
			return this.$store.state.totalTravellers;
		},
		stripePaymentAmount() {
			return this.$store.state.stripePaymentAmount;
		},
		stripeClientSecret() {
			return this.$store.state.stripeClientSecret;
		},
		selectedEvent() {
			return this.$store.state.selectedEvent;
		},
		selectedAvailabilty() {
			return this.$store.state.availability;
		},
		userProfile() {
			return this.$store.state.userProfile;
		},
		loggedInUser() {
			return this.$store.state.loggedInUser;
		},
		paymentDescription() {
			let paymentDescription = "";

			if (this.adHocPayment) {
				paymentDescription = this.paymentDescriptor;
			} else {
				if (this.activeOrder) {
					paymentDescription = `Payment towards Soca Islands ${this.activeOrder?.trip_event?.name
						} -  ${this.formatDate(
							this.activeOrder?.trip_date?.start_date,
							"MMM d yyyy"
						)} to  ${this.formatDate(this.activeOrder?.trip_date?.end_date, "MMM d yyyy")}`;
				} else {
					paymentDescription = `Payment towards Soca Islands ${this.selectedEvent?.name
						} - ${this.formatDate(
							this.selectedAvailabilty?.start_date,
							"MMM d yyyy"
						)} to ${this.formatDate(this.selectedAvailabilty?.end_date, "MMM d yyyy")}`;
				}
			}

			return paymentDescription;
		},
	},
	data() {
		return {
			formatCurrency,
			formatDate,
			isBusy: false,
			paymentMethod: null,
			cardholder: {
				name: "",
				email: "",
				phone: "",
			},
			elements: null,
			stripe: null,
			showPaymentError: false,
			paymentErrorMessage: "",
		};
	},
	methods: {
		async initStripe() {
			//get stripe amount
			this.stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);
			const options = {
				clientSecret: await this.getStripeClientSecret(),
			};
			const appearance = {
				theme: "stripe",
				variables: {
					colorText: "#32325d",
				},
			};
			this.elements = this.stripe.elements(options, appearance);
			const paymentElement = this.elements.create("payment");
			paymentElement.mount("#payment-element");
		},
		async getStripeClientSecret() {
			if (!this.stripeClientSecret) {
				const paymentIntent = await fetch(
					"https://app.socaislands.com/api/stripe/create",
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							amount: this.stripePaymentAmount,
							description: this.paymentDescription,
							currency: "usd",
							statement_descriptor_suffix: "SOCA",
							capture_method: this.captureMethod,
							receipt_email: this.receiptEmail ?? this.loggedInUser.email,
							setup_future_usage: "off_session",
						}),
					}
				)
					.then((response) => response.json())
					.then((data) => {
						return data;
					});

				this.$store.commit("setStripeClientSecret", paymentIntent.client_secret);

				return paymentIntent.client_secret;
			} else {
				return this.stripeClientSecret;
			}
		},
		async chargeCreditCard() {
			const { paymentIntent, error } = await this.stripe.confirmPayment({
				elements: this.elements,
				confirmParams: {
					return_url: "https://apps.socaislands.com/bookings",
				},
				redirect: "if_required",
			});

			if (error) {
				console.log(error);
				this.showPaymentError = true;
				this.paymentErrorMessage = error.message;
				this.$store.commit("setIsReservationFormBusy", false);
				return { error: error };
			} else if (paymentIntent) {
				return { paymentIntent: paymentIntent };
			}
		},
		selectedPaymentMethodChange() {
			this.$store.commit("setSelectedPaymentMethod", this.paymentMethod);
			if (this.paymentMethod === "credit_card") {
				this.initStripe();
			}
		},
		async submitBookingForm() {
			this.$store.commit("setIsReservationFormBusy", true);
			switch (true) {
				case this.paymentMethod === "credit_card":
					const { paymentIntent } = await this.chargeCreditCard();
					if (paymentIntent) {
						this.saveReservation(paymentIntent);
					}
					break;
				case this.paymentMethod === "zelle":
					this.saveReservation();
					break;
				default:
					console.log("default");
			}
		},
		async saveReservation(paymentIntent) {
			const { orderId, error } = await this.$store.dispatch("saveReservation", {
				orderStatus: "in-review",
			});
			if (orderId) {
				await this.$store.dispatch("saveTravellerDetails", { orderId: orderId });
				if (this.paymentMethod === "credit_card") {
					await this.$store.dispatch("saveTransaction", {
						orderId: orderId,
						paymentIntent: paymentIntent || null,
					});
				}
				await this.$store.dispatch("sendNewEmail", {
					toUids: [this.userProfile.id],
					template: {
						name: emailTemplates.NEW_BOOKING,
						data: { fname: this.userProfile.fname, event: this.selectedEvent.name },
					},
				});
				toast.success("Reservation saved.", {
					timeout: 2000,
				});
				setTimeout(() => (window.location.href = "/bookings"), 2500);
			} else if (error) {
				toast.error("There was an error saving your reservation.", {
					timeout: 6000,
				});
				this.$store.commit("setIsReservationFormBusy", false);
			}
		},
		async submitAddPaymentForm({ orderId, paymentAmount }) {
			this.$store.commit("setIsReservationFormBusy", true);
			switch (true) {
				case this.paymentMethod === "credit_card":
					const { paymentIntent } = await this.chargeCreditCard();
					if (paymentIntent) {
						await this.$store.dispatch("saveTransaction", {
							orderId: orderId,
							paymentIntent: paymentIntent || null,
						});
						toast.success("Reservation balance updated.", {
							timeout: 2000,
						});
						this.applyPaymentsToTravellers({ orderId, paymentAmount });
						this.$store.dispatch("resetReservation");
					}
					break;
				case this.paymentMethod === "zelle":
					await this.$store.dispatch("saveTransaction", {
						orderId: orderId,
						paymentIntent: null,
						userEmail: this.activeOrder.user,
					});
					this.applyPaymentsToTravellers({ orderId, paymentAmount });
					this.$store.dispatch("resetReservation");
					break;
				default:
					console.log("default");
			}
		},
		async submitAdHocPayment({ orderId, userEmail }) {
			this.$store.commit("setIsReservationFormBusy", true);
			try {
				switch (true) {
					case this.paymentMethod === "credit_card":
						const { paymentIntent } = await this.chargeCreditCard();
						if (paymentIntent) {
							await this.$store.dispatch("saveTransaction", {
								orderId: orderId,
								paymentIntent: paymentIntent || null,
								userEmail,
							});
						}
						break;
					case this.paymentMethod === "zelle":
						await this.$store.dispatch("saveTransaction", {
							orderId: orderId,
							paymentIntent: null,
							userEmail: "accounting@socaislands.com",
						});
						break;
					default:
						console.log("default");
				}

				return { response: true };
			} catch (error) {
				console.log(error);
				return { error };
			} finally {
				this.$store.commit("setIsReservationFormBusy", false);
			}
		},
		async applyPaymentsToTravellers({ orderId, paymentAmount }) {
			const batch = writeBatch(db);
			let orderPayment = 0;

			Object.keys(paymentAmount).forEach((acct) => {
				const paymentsRef = doc(
					db,
					`${collections.ORDERS}/${orderId}/${subCollections.TRAVELLERS}`,
					acct
				);
				const amount = paymentAmount[acct] || 0;
				orderPayment = parseFloat(orderPayment + amount);
				batch.update(paymentsRef, { order_payments: increment(amount) });
			});

			const orderPaymentRef = doc(db, collections.ORDERS, orderId);
			const batchWrite = batch.update(orderPaymentRef, {
				order_payments: increment(orderPayment),
			});
			if (batchWrite) {
				this.$store.dispatch("sendNewEmail", {
					toUids: [this.userProfile.id],
					cc: [],
					template: {
						name: emailTemplates.NEW_PAYMENT,
						data: {
							fname: this.userProfile.fname,
							acct_names: [].join("and "),
							order_ref: this.activeOrder.ref,
							amount: this.formatCurrency(orderPayment),
						},
					},
				});
			}
			return await batch.commit();
		},
	},
	mounted() { },
};
</script>
<style scoped>
.cc-logos img {
	height: 15px;
	margin: 0 3px;
}
</style>
